import './App.css';
import CanvasLayout from './components/canvas/Canvas';
import Overlay from './components/pages/Overlay';
import { usePanel } from './components/hooks/usePanel';
import classNames from 'classnames';
import ControlPanel from './components/pages/ControlPanel';
import { useRef } from 'react';


function App() {

  const showPanel = usePanel((state) => state.showPanel);
  
  return (
    <>
      <div className="flex flex-row overflow-y-hidden absolute w-full h-full">
          <div className={classNames({
              'w-4/5': showPanel,
              'w-full': !showPanel,
              },"bg-gray-100 rounded transition-all duration-900")}>
              <CanvasLayout/>
          </div>
          <div className={classNames({
              'w-2/5': showPanel,
              'w-1': !showPanel,
              },"overflow-y-scroll bg-gray-100 rounded transition-all duration-900")}>
              <ControlPanel/>
          </div>
      </div>
      <Overlay/>
    </>
  );
}

export default App;
