import { useEffect, useState, Suspense } from "react";
import { OrbitControls } from "@react-three/drei";

const Controls = () => {

    const [azimuthMin, setAzimuthMin] = useState(0.7);
    const [azimuthMax, setAzimuthMax] = useState(0.7);
    const [distanceMin, setDistanceMin] = useState(6);
    const [distanceMax, setDistanceMax] = useState(6);
    const [polarAngleMin, setPolarMin] = useState(Math.PI * 0.45);
    const [polarAngleMax, setPolarMax] = useState(Math.PI * 0.45);
    
    
    useEffect(() => {
        setAzimuthMin(0)
        setAzimuthMax(Math.PI * 1.9999)
        setDistanceMax(30)
        setDistanceMin(1)
        setPolarMin(0)
        setPolarMax(Math.PI / 1.5)
    })
    
    
    return (
      <>
      <Suspense fallback={null}>
        {/* <TransformControls 
            ref={transform} 
            enabled={true} 
            mode={mode}
            space={'local'}
            /> */}
        {/* {snap.current && <TransformControls object={scene.getObjectByName(snap.current)} mode={'translate'} />}  */}
        <OrbitControls
            makeDefault
            target={[0, 0.5, 0]}
            enablePan={true}
            minAzimuthAngle={azimuthMin} //0.5
            maxAzimuthAngle={azimuthMax} //0.5
            minPolarAngle={polarAngleMin}
            maxPolarAngle={polarAngleMax} //Math.PI / factor
            maxDistance={distanceMax}
            minDistance={distanceMin}
        />
      </Suspense>
      </>
    )
}


export default Controls;