import { Suspense, useState } from "react";
import Slider from "@mui/material/Slider"
import { useSlider } from "../hooks/useSlider";


const style: React.CSSProperties = {
    zIndex: 1,
    position:'absolute',
    height: '30px',
    width: '30px',
    background: 'white',
    textAlign:'center',
    borderRadius: '100%',
    fontSize: 10,
    fontWeight: 'bold',
    opacity: 0.7,
    border: '1px solid black',
    cursor: 'pointer'
}



const SpeedSlider = ({}) => {
    
    const setSpeed = useSlider((state) => state.setSpeed);
    
    const [silderValue, setSliderValue] = useState<number>(15);

    const handleChange = (event: Event, newValue: number | number[]) => {
        setSliderValue(newValue as number);
        setSpeed(newValue as number)
    };

    return(
        <Suspense fallback={null}>
             <div className="grid grid-rows-2 gap-1">
                <div>Transform Speed</div>
                <Slider
                size="small"
                min={1}
                step={1}
                max={30}
                onChange={handleChange}
                value={silderValue}
                valueLabelDisplay="auto"
                aria-labelledby="non-linear-slider"
                />
            </div>
        </Suspense>
    )
}

export default SpeedSlider