const Plane = () => {
   
    return (
        <>
            <mesh 
            //plane that receives softShadow
            rotation={[-Math.PI / 2, 0, 0]} 
            position={[0, 0, 0]} 
            receiveShadow>
                <planeGeometry args={[100, 100]} />
                <shadowMaterial transparent opacity={0.2} />
            </mesh>
            <mesh 
            rotation={[-Math.PI / 2, 0, 0]} 
            position={[0, -0.01, 0]} 
            receiveShadow>
                <planeGeometry args={[100, 100]} />
                <meshStandardMaterial color={'#ffffff'} />
            </mesh>
            <gridHelper args={[100, 100]}/>
        </>
    );
};

export default Plane;
