import create from "zustand";

export interface TargetState {
    imageUrl: string;
    updateImage: boolean;
    setImage: (value: string) => void;
    setUpdateImage: (value: boolean) => void;
}

export const useImage = create<TargetState>((set) => ({
    imageUrl: 'undefined',
    updateImage: true,
    setImage: (value) =>
        set((state) => {
        return {
            ...state,
            imageUrl: value
        };
    }),
    setUpdateImage: (value) =>
        set((state) => {
        return {
            ...state,
            updateImage: value
        };
        })
}));


// export interface PanelState {
//     showPanel: boolean;
//     togglePanel: (value: boolean) => void;
// }

// export const usePanel = create<PanelState>((set) => ({
//     showPanel: false,
//     togglePanel: () =>
//         set((state) => {
//         return {
//             ...state,
//             showPanel: !state.showPanel
//         };
//         })
// }));
