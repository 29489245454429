import create from "zustand";

export interface PanelState {
    showPanel: boolean;
    togglePanel: (value: boolean) => void;
}

export const usePanel = create<PanelState>((set) => ({
    showPanel: false,
    togglePanel: () =>
        set((state) => {
        return {
            ...state,
            showPanel: !state.showPanel
        };
        })
}));
