import axios from "axios";
import { useEffect, useState } from "react";
import { useImage } from "../hooks/useImage";
import { useSearchParams } from 'react-router-dom'
import { useTargets } from "../hooks/useTargets";
import { useModel } from "../hooks/useModel";
import { useTransforms } from "../hooks/useTransforms";
import { useScreenshot } from "../hooks/useScreenshot";
import { useEnvironment } from "../hooks/useEnvironment";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import { useAnimationsGlobal } from "../hooks/useAnimationsGlobal";
import { useBoundingBox } from "../hooks/useBoundingBox";


const Server = () => {

  const [loading, setLoading] = useState<boolean>(true);
  const [isPosted, setIsPosted] = useState<boolean>(false);
  const [isNotPosted, setIsNotPosted] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [isInitallyPosted, setIsInitiallyPosted] = useState<boolean>(false);
  const [rescaleNeeded, setRescaleNeeded] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [data, setData] = useState<any>();
  const [successMessage, setSuccessMessage] = useState<string>("");

  const { setImage } = useImage((state) => state);
  const { targets, setTargets } = useTargets((state) => state);
  const { hasLoaded, setModel, setModelHasLoaded } = useModel((state) => state);
  const [searchParams] = useSearchParams();
  const {position, rotation, scale, setPosition, setRotation, setScale } = useTransforms((state) => state)
  const { loadedScreenshot, screenshot, setScreenshot, loadScreenshot } = useScreenshot((state) => state)
  const { env, setEnvironment } = useEnvironment((state) => state)
  const {currentAnimation, playingAnimation, playAnimations, setCurrentAnimation} = useAnimationsGlobal((state) => state)
  const { hasRescaled, setHasRescaled } = useBoundingBox((state) => state);
 

  const targetId = searchParams.get("containerId")
  const token = searchParams.get("token")
  const projectId = searchParams.get("projectId")
  const isProduction = searchParams.get("isProduction")

  
  useEffect(() => {
    if( hasRescaled === true && hasLoaded === true && rescaleNeeded === true){
      setIsInitiallyPosted(true)
      updateTransfroms() 
      updateAnimation();
      setHasRescaled(true)
      setTimeout(() => {
        loadScreenshot(true)   
        // console.log('posting')   
        // console.log(screenshot)     
        pushScreenshot()
        postData();
      }, 2000)
    }
  },[hasRescaled])

  const setRescaled = (target: any) => {
    // delete target.content[0].configurations['hasRescaled']
    // console.log(target.content[0].configurations, target.content[0].configurations.hasOwnProperty('hasRescaled'))
    if(target.content[0].configurations.hasOwnProperty('hasRescaled') === true){
      setScale(target.content[0].configurations.scale.x)
      setHasRescaled(true)
      setSuccessMessage('Successfully saved!')
      console.log('hasRescale value!')
      console.log()
    }else{
      setRescaleNeeded(true)
      //to ensure bounding box scales correctly
      setScale(1)
      loadScreenshot(true)   
      setSuccessMessage('Automatically saved!')
      console.log('NOT hasRescale value!')
      setIsInitiallyPosted(true)
    }
  }

  const setConfigsOfServer = () => {
    targets.map(target => 
      {if(target.id === targetId){
        // console.log(target.content[0].configurations)
        if(typeof target.content[0].configurations.environment === 'string'){
          setEnvironment(target.content[0].configurations.environment)
        }
        setCurrentAnimation(target.content[0].configurations.animation, 'init');
        playAnimations(target.content[0].configurations.playAnimation)
        setImage(target.anchors[0].sources.main);
        setModel(target.content[0].sources.main);
        //setModel('./riverdance_dance_free_animation.glb');
        //setModel('./flying_bee.glb');
        //setModel('./Playful dog (2).glb');
        //setModel('./BoomBox.glb');
        //setModel('./bmw_1m_compressed.glb');
        //setModel('./Dwarf.glb');
        //setModel('./lambo.glb');
        //setModel('./Stork.glb');
        setRescaled(target)
        setPosition([target.content[0].configurations.position.x, target.content[0].configurations.position.y, target.content[0].configurations.position.z]);
        setRotation([target.content[0].configurations.rotation._x, target.content[0].configurations.rotation._y, target.content[0].configurations.rotation._z]);
        setModelHasLoaded(true);
      }}
    )
  }

  const fetchData = async () => {
    setLoading(true);
    const url = isProduction === 'true' ? "https://ilguides-prod-func.azurewebsites.net/api/project?projectId=": "https://ilguides-stag-func.azurewebsites.net/api/project?projectId="
    try {
      const {data: response} = await axios.get(`${url}${projectId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
        }
      });
      //delete response.containers[0].content[0].configurations['hasRescaled']
      console.log('fetch: ')
      console.log(response.containers[0].content[0].configurations)
      setData(response)
      setTargets(response.containers)
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message)
      } else {
        setErrorMessage('Unexpected error')
      }
      setError(true)
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() => {
    if(hasLoaded === false){
      setConfigsOfServer();
    }
  })

  const updateTransfroms = () => {
    //console.log(position, rotation, scale)
    //{data !== undefined && (
      targets.map((target: any) => {
        if(target.id === targetId){
          target.content[0].configurations.position.x = position[0];
          target.content[0].configurations.position.y = position[1];
          target.content[0].configurations.position.z = position[2];
          target.content[0].configurations.rotation._x = rotation[0];
          target.content[0].configurations.rotation._y = rotation[1];
          target.content[0].configurations.rotation._z = rotation[2];
          target.content[0].configurations.scale.x = scale[0];
          target.content[0].configurations.scale.y = scale[1];
          target.content[0].configurations.scale.z = scale[2];
          //console.log(scale)
          target.content[0].configurations.playAnimation = playingAnimation;
          if(hasRescaled === true){
            target.content[0].configurations.hasRescaled = true;
          }
        }
      })
    //)}
  }

  
  const updateAnimation = () => {
    //{data !== undefined && (
      targets.map((target: any) => {
        if(target.id === targetId){
          if(currentAnimation.name !== 'undefined'){
            target.content[0].configurations.animation = currentAnimation.index;
            target.content[0].configurations.playAnimation = playingAnimation;
          }
          else if(currentAnimation.name === 'undefined'){
            //console.log(target.configurations.animation)
            target.content[0].configurations.animation = null;
            target.content[0].configurations.playAnimation = false;
          }
        }
      })
    //)}
  }


  const pushScreenshot = () => {
    //console.log(data.targets)
    //{data !== undefined && (
      targets.map((target: any) => {
        if(target.id === targetId){
          target.content[0].configurations.screenshot = screenshot;
          target.content[0].configurations.environment = env;
        }
      })
    //)}
  }


  const postData = async () => {
    //const url = "https://ilguides-stag-func.azurewebsites.net/api/UpdateStaticProject"    
    const url = isProduction === 'true' ? "https://ilguides-prod-func.azurewebsites.net/api/project/update": "https://ilguides-stag-func.azurewebsites.net/api/project/update"
    //console.log('post: ' + url)
    axios.post(url, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      }
    })
    .then(response => {
      console.log('posted: ')
      console.log(response.data.containers[0].content[0].configurations)
      setIsPosted(true);
      window.postMessage("3dObject.Saved")
      setTimeout(() => setIsPosted(false), 3000);
    })
    .catch(({response}) => {
      setIsNotPosted(true)
      setTimeout(() => setIsNotPosted(false), 3000);
    });
  };

//console.log(isPosted, isInitallyPosted)
  return (
    <div>
    {loading &&
      <>
      <div className="flex flex-col justify-center w-full h-full p-32 absolute z-10">
        <span className={"text-center text-xl font-medium text-slate-500"}>Loading ...</span>
      </div>
      </>}
    {!loading && (
      <>
      <div className="flex flex-row justify-end w-full px-36 pt-5 absolute z-10">
        <button 
          className={"absolute py-2 px-4 bg-blue-500 text-white font-bold text-white uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"}
          onClick={() => {  
            //setIsInitiallyPosted(true);  
            setSuccessMessage('Successfully saved!')
            loadScreenshot(true)           
            pushScreenshot()
            updateTransfroms() 
            updateAnimation();
            postData()       
          }}>
          {'SAVE'}
        </button>
        {isPosted &&
        <div className="flex flex-row justify-center w-full text-center">
          <div className="p-2 bg-blue-700 items-center text-blue-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
            <span className="flex rounded-full bg-blue-500 uppercase px-2 py-1 text-xs font-bold mr-3">CONGRATS</span>
            <span className="font-semibold mr-2 text-left flex-auto">{successMessage}</span>
          </div>
        </div>}
        {isNotPosted &&
        <div className="flex flex-row justify-center w-full text-center">
          <div className="p-2 bg-red-700 items-center text-red-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
            <span className="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3">ALERT</span>
            <span className="font-semibold mr-2 text-left flex-auto">Not saved!</span>
          </div>
        </div>}
        {isError &&
        <div className="flex flex-row justify-center w-full text-center">
          <div className="p-2 bg-red-700 items-center text-red-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
            <span className="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3">ERROR</span>
            <span className="font-semibold mr-2 text-left flex-auto">{errorMessage}</span>
          </div>
        </div>}
      </div>
      </>
    )}
    </div>
  )
}

export default Server;