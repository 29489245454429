import { useFrame } from "@react-three/fiber";
import { useModel } from "../hooks/useModel";
import { useSlider } from "../hooks/useSlider";
import { useTransforms } from "../hooks/useTransforms";


// function parseRotation(rotation: [number, number, number], speed: number, axis: string, direction: string){
//     const {modelRef}  = useModel((state) => state)
//     console.log(modelRef)
//     // var euler = new Euler(rotation[0], rotation[1], rotation[2]);
//     // let quaternion = new Quaternion();
//     // quaternion.setFromEuler(euler);
//     // if(direction === 'increase'){
//     //     if(axis === 'X' || axis === 'x'){ quaternion.x = quaternion.x + speed }
//     //     else if(axis === 'Y' || axis === 'y'){ quaternion.y = quaternion.y + speed }
//     //     else if(axis === 'Z' || axis === 'z'){ quaternion.z = quaternion.z + speed }
//     // }else if(direction === 'decrease'){
//     //     if(axis === 'X' || axis === 'x'){ quaternion.x = quaternion.x - speed }
//     //     else if(axis === 'Y' || axis === 'y'){ quaternion.y = quaternion.y - speed }
//     //     else if(axis === 'Z' || axis === 'z'){ quaternion.z = quaternion.z - speed }
//     // }
//     // euler.setFromQuaternion( quaternion )
//     // rotation[0] = euler.x
//     // rotation[1] = euler.y
//     // rotation[2] = euler.z
//     if(direction === 'increase'){
//         if(axis === 'X' || axis === 'x'){ rotation[0] = rotation[0] + speed }
//         else if(axis === 'Y' || axis === 'y'){ rotation[1] = rotation[1] + speed }
//         else if(axis === 'Z' || axis === 'z'){ rotation[2] = rotation[2] + speed }
//     }else if(direction === 'decrease'){
//         if(axis === 'X' || axis === 'x'){ rotation[0] = rotation[0] - speed }
//         else if(axis === 'Y' || axis === 'y'){ rotation[1] = rotation[1] - speed }
//         else if(axis === 'Z' || axis === 'z'){ rotation[2] = rotation[2] - speed }
//     }
//     return rotation
// }

export let directions = new Set<string>();

const ButtonController = () => {
    const {modelRef}  = useModel((state) => state)

    const {increasePositionX, decreasePositionX,
        increasePositionY, decreasePositionY,
        increasePositionZ, decreasePositionZ,
        increaseRotationX, decreaseRotationX,
        increaseRotationY, decreaseRotationY,
        increaseRotationZ, decreaseRotationZ,
        increaseScale, decreaseScale} = useTransforms((state) => state);

    const speed = useSlider((state) => state.speed);
   
    useFrame(() => {
        if(directions.has('positionNegX')){
            decreasePositionX(speed)
        }
        if(directions.has('positionPositiveX')){
            increasePositionX(speed)
        }
        if(directions.has('positionNegY')){
            increasePositionZ(speed)
            //decreasePositionY(speed)
        }
        if(directions.has('positionPositiveY')){
            decreasePositionZ(speed)
            //increasePositionY(speed)
        }
        if(directions.has('positionPositiveZ')){
            //increasePositionZ(speed)
            decreasePositionY(speed)
        }
        if(directions.has('positionNegZ')){
            increasePositionY(speed)
            //decreasePositionZ(speed)
        }
        // if(directions.has('rotateNegX')){
        //     //modelRef.rotateX(speed)
        //     increaseRotationZ(speed)
        // }
        // if(directions.has('rotatePosX')){
        //     decreaseRotationZ(speed)
        // }
        // if(directions.has('rotateNegY')){
        //     decreaseRotationY(speed)
        // }
        // if(directions.has('rotatePosY')){
        //     increaseRotationY(speed)
        // }
        // if(directions.has('rotatePosZ')){
        //     increaseRotationX(speed)
        // }
        // if(directions.has('rotateNegZ')){
        //     decreaseRotationX(speed)
        // }
        if(directions.has('smaller')){
            decreaseScale(speed)
        }
        if(directions.has('bigger')){
            increaseScale(speed)
        }
    })

    return null
};

export default ButtonController;
