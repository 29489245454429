import { Suspense, useEffect } from "react";
import { useBoundingBox } from "../hooks/useBoundingBox";


const BoundingBoxButton = ({}) => {

    const { showBoundingBox, toggleBoundingBoxVisibility } = useBoundingBox((state) => state);
    

    useEffect(() => {
        const checkbox = document.getElementById('bbox-toggle-button') as HTMLInputElement;
        if(checkbox !== null){
            checkbox.checked = showBoundingBox
        }
    })

    return(
        <Suspense fallback={null}>
            {/* <h1 className="mt-1">Bounding Box</h1> */}
            <div className="flex flex-col m-1 mt-3">
                <label>
                    <input 
                        id={'bbox-toggle-button'}
                        className="scale-150"
                        type="checkbox" 
                        defaultChecked={false} 
                        onClick={() => {toggleBoundingBoxVisibility()}} 
                        />
                    <span />
                    <a className="p-3">{'Show Bounding Box'}</a>
                </label>
                {/* <button
                    className="h-8 mb-5 bg-blue-500 hover:bg-blue-700 text-white font-bold text-sm rounded"
                    onClick={() => {       
                        toggleBoundingBoxVisibility(showBoundingBox)   
                       // console.log(showBoundingBox)
                    }}
                    >
                    {'Show Bounding Box'}
                </button>             */}
            </div>
        </Suspense>
    )
}

export default BoundingBoxButton