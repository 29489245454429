import { useAnimationsGlobal } from "../hooks/useAnimationsGlobal";
import { useEffect } from "react";
import { useTransforms } from "../hooks/useTransforms";
var data = require('../json/project.json')

let json = data.targets[0].configurations;

const JsonController = ({}) => {

  const {position, rotation, scale } = useTransforms((state) => state)
  const {currentAnimation} = useAnimationsGlobal((state) => state)

  useEffect(() => {
    json.position.x = position[0];
    json.position.y = position[1];
    json.position.z = position[2];
    json.rotation.x = rotation[0];
    json.rotation.y = rotation[1];
    json.rotation.z = rotation[2];
    json.rotation._x = rotation[0];
    json.rotation._y = rotation[1];
    json.rotation._z = rotation[2];
    json.scale.x = scale[0];
    json.scale.y = scale[1];
    json.scale.z = scale[2];
    json.animation = currentAnimation.index; //.toString();
  })

  return(null);
};

export default JsonController