import { useCamera } from "../hooks/useCamera";
import { useThree } from "@react-three/fiber";
import { useEffect } from "react";
import { Vector3 } from "three";


const lookAtPos = new Vector3()

const CameraController = () => {

    const camera = useThree()
    const cameraPosition = useCamera((state) => state)
   
    // lookAtPos.x = 0;
    // lookAtPos.y = 10;
    // lookAtPos.z = 0;

    useEffect(() => {
        //camera.camera.lookAt(lookAtPos)
        camera.camera.position.x = cameraPosition.cameraPosition[0];
        camera.camera.position.y = cameraPosition.cameraPosition[1];
        camera.camera.position.z = cameraPosition.cameraPosition[2];
    },[cameraPosition])

    return null
};

export default CameraController;
