import { Suspense } from "react";
import { directions } from "../canvas/ButtonControler";
import { useTransforms } from "../hooks/useTransforms";


const ScaleButtons = ({}) => {
    
    const { setMode } = useTransforms((state) => state)

    return(
        <Suspense fallback={null}>
            <h1>Scale</h1>
            <div className="grid grid-cols-2 gap-1 mb-2">
                <button
                    className="h-8 bg-blue-500 hover:bg-blue-700 text-white font-bold text-sm rounded"
                    onMouseDown={() => {                 
                        directions.add('smaller')
                        setMode('translate')
                    }}
                    onMouseUp={() => {           
                        directions.delete('smaller')
                    }}
                    onMouseEnter={() => {}}
                    onMouseOut={() => {
                        directions.delete('smaller')
                    }}
                    >
                    {'SMALLER'}
                </button>   
                <button
                    className="h-8 bg-blue-500 hover:bg-blue-700 text-white font-bold text-sm rounded"
                    onMouseDown={() => {                 
                        directions.add('bigger')
                        setMode('translate')
                    }}
                    onMouseUp={() => {           
                        directions.delete('bigger')
                    }}
                    onMouseEnter={() => {}}
                    onMouseOut={() => {
                        directions.delete('bigger')
                    }}
                    >
                    {'BIGGER'}
                </button>               
            </div>
        </Suspense>
    )
}

export default ScaleButtons