import create from "zustand";

export interface CameraState {
    cameraPosition: [number, number, number];
    setCameraPosition: (value: [number, number, number]) => void;
}

export const useCamera = create<CameraState>((set) => ({
    cameraPosition: [4, 2, 5],
    setCameraPosition: (value) =>
        set((state) => {
        return {
            ...state,
            cameraPosition: value
        };
    })
}));
