import create from "zustand";

export interface SliderState {
    speed: number;
    setSpeed: (value: number) => void;
}

export const useSlider = create<SliderState>((set) => ({
    speed: 15 * 0.002,
    setSpeed: (value) =>
        set((state) => {
        return {
            ...state,
            speed: value * 0.002
        };
        })
}));
