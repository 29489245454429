import { Bounds, Box, Environment, GizmoHelper, GizmoViewport, useBounds, useGLTF } from "@react-three/drei";
import { useFrame, useStore} from "@react-three/fiber";
import { Suspense, useEffect, useMemo, useRef} from "react";
import { AnimationMixer, Box3, Vector3, Euler, Mesh, Event, Object3D } from "three";
import { GLTF } from 'three-stdlib';
import { useModel } from "../hooks/useModel";
import { useAnimationsGlobal } from "../hooks/useAnimationsGlobal";
import { useTransforms } from "../hooks/useTransforms";
import { useBoundingBox } from "../hooks/useBoundingBox";
import { useEnvironment } from "../hooks/useEnvironment";
import { directions } from "../canvas/ButtonControler";
import { useSlider } from "../hooks/useSlider";
import { useImage } from "../hooks/useImage";

interface ModelProps {
    //url: string;
    position: [number, number, number];
    //rotation: [number, number, number];
    scale: [number, number, number];
}


type DreiGLTF = GLTF & {
    nodes: Record<string, THREE.Mesh>;
    materials: Record<string, THREE.MeshStandardMaterial>;
};


   
let boundingbox = new Box3();
let bboxDiagonal = new Vector3();
let bboxCenter = new Vector3();
let bboxScaled = new Vector3();
let diagonalLength = 0;
let scalerBoundingBox = 1;

export const Model = (props: ModelProps) => {
    
    const { env } = useEnvironment((state) => state);
    const { isInitialized, setIsInitialized}  = useModel((state) => state)    
    const model = useModel((state) => state.model);
    const speed = useSlider((state) => state.speed);
    const { scale, rotation, resetRotate, updateRotate, setUpdateRotate, setResetRotate, setRotationX, setRotationY, setRotationZ, setRotation, setScale} = useTransforms((state) => state);
    const modelRef = useRef<any>()
    const { currentAnimation, initAnimationButtons, playingAnimation } = useAnimationsGlobal((state) => state);
    const { showBoundingBox, hasRescaled, setHasRescaled } = useBoundingBox((state) => state);
    
         
    const { scene, nodes, materials, animations } = useGLTF(model) as unknown as DreiGLTF
        


    useEffect(() => {
        if(modelRef.current !== undefined){
            if( hasRescaled === false ){
                boundingbox.setFromObject(modelRef.current);
                boundingbox.getCenter(bboxCenter)
                bboxDiagonal.subVectors(boundingbox.max, boundingbox.min)
                diagonalLength = bboxDiagonal.length();
                scalerBoundingBox = 1 / (diagonalLength / 2)
                bboxDiagonal.multiplyScalar(scalerBoundingBox)
                bboxCenter.multiplyScalar(scalerBoundingBox)
                setScale(scalerBoundingBox) 
                setHasRescaled(true)
            }
        }
    },[modelRef.current])

    
    // console.log("Rescaled: " + hasRescaled)

    useEffect(() => {
        if(modelRef.current !== undefined){
            boundingbox.setFromObject(modelRef.current);
            boundingbox.getCenter(bboxCenter)
            bboxDiagonal.subVectors(boundingbox.max, boundingbox.min)
            diagonalLength = bboxDiagonal.length();
            scalerBoundingBox = 1 / ((diagonalLength + 0.001) / 2.5)
        }
    },[rotation, props.scale, props.position])
    
    useMemo(() => 
        Object.values(nodes).forEach(obj => 
            {obj.castShadow === false && Object.assign(obj, { castShadow: true, receiveShadow: true })}
        ), [nodes]   
    )

    function rotateModelOnButtonPress(){
        if(directions.has('rotateNegX')){
            modelRef.current.rotateZ(speed)
            setRotation([modelRef.current.rotation._x, modelRef.current.rotation._y, modelRef.current.rotation._z])
        }
        if(directions.has('rotatePosX')){
            modelRef.current.rotateZ(-speed)
            setRotation([modelRef.current.rotation._x, modelRef.current.rotation._y, modelRef.current.rotation._z])
        }
        if(directions.has('rotateNegY')){
            modelRef.current.rotateY(speed)
            setRotation([modelRef.current.rotation._x, modelRef.current.rotation._y, modelRef.current.rotation._z])
        }
        if(directions.has('rotatePosY')){
            modelRef.current.rotateY(-speed)
            setRotation([modelRef.current.rotation._x, modelRef.current.rotation._y, modelRef.current.rotation._z])
        }
        if(directions.has('rotatePosZ')){
            modelRef.current.rotateX(speed)
            setRotation([modelRef.current.rotation._x, modelRef.current.rotation._y, modelRef.current.rotation._z])
        }
        if(directions.has('rotateNegZ')){
            modelRef.current.rotateX(-speed)
            setRotation([modelRef.current.rotation._x, modelRef.current.rotation._y, modelRef.current.rotation._z])
        }
        if(resetRotate === true){
            const a = new Euler( rotation[0], rotation[1], rotation[2], 'XYZ' )
            modelRef.current.setRotationFromEuler(a)
            setResetRotate(false)
        }
        if(updateRotate === true){
            const a = new Euler( rotation[0], rotation[1], rotation[2], 'XYZ' )
            modelRef.current.setRotationFromEuler(a)
            setUpdateRotate(false)
        }
    }

    function setInitialRotation(rotation: [number, number, number]){
        if(modelRef.current !== undefined && isInitialized === false){
            const a = new Euler( rotation[0], rotation[1], rotation[2], 'XYZ' )
            modelRef.current.setRotationFromEuler(a)
            setIsInitialized(true)
        }
    }

    //ANIMATIONS
    useEffect(() => {
        animations.forEach((animation) => initAnimationButtons(animation.name));
    },[])
 
    let mixer = new AnimationMixer(scene);
    
    useFrame((state, delta) => {
        mixer.update(delta);
        rotateModelOnButtonPress();  
        setInitialRotation(rotation)
    });
      

    useEffect(() => {
        if(Object.keys(animations).length !== 0){
            if( playingAnimation === true){
                void mixer.clipAction(animations[currentAnimation.index]).reset().fadeIn(0.5).play();
            }
        }
    })


    return (
        <Suspense fallback={null}>
            <GizmoHelper 
                alignment="top-left" 
                margin={[80, 80]}>
                <GizmoViewport />
            </GizmoHelper> 
            <Box 
                visible={showBoundingBox}
                position={[bboxCenter.x, bboxCenter.y, bboxCenter.z]}
                args={[bboxDiagonal.x, bboxDiagonal.y, bboxDiagonal.z]}>
                <meshStandardMaterial transparent opacity={0.3} color="blue" />
            </Box>
            <Suspense fallback={null}>
                <primitive  
                    ref={modelRef}              
                    object={scene} 
                    position={props.position}
                    scale={props.scale}/> 
            </Suspense>            
            <Environment            
                background={false} 
                files={env}/> 
        </Suspense>        
    );
}

export default Model;