import { useCamera } from "../hooks/useCamera";
import { Suspense } from "react";
import { useAnimationsGlobal } from "../hooks/useAnimationsGlobal";
import { useTransforms } from "../hooks/useTransforms";
import { useBoundingBox } from "../hooks/useBoundingBox";


const ResetButtons = ({}) => {

    //const { boundingScaleFactor } = useBoundingBox((state) => state)
    const { setPosition, setRotation, setScale, setResetRotate, setResetTranslate, setResetScale } = useTransforms((state) => state);
    const { setCameraPosition } = useCamera((state) => state)
    const { setBoundingBoxVisibility } = useBoundingBox((state) => state);


    return(
    <Suspense fallback={null}>
        <h1>Reset</h1>
        <div className="grid grid-cols-2 gap-1 mb-2">             
            <button
                className="h-8 bg-blue-500 hover:bg-blue-700 text-white font-bold text-sm rounded"
                key={'Translation-reset'}
                onClick={() => {               
                    setPosition([0,0,0])
                    setResetTranslate(true)
                    setBoundingBoxVisibility(false)
                }}
                >
                Translation
            </button>
            <button
                className="h-8 bg-blue-500 hover:bg-blue-700 text-white font-bold text-sm rounded"
                key={'Rotation-reset'}
                onClick={() => {               
                    setRotation([0,0,0])
                    setResetRotate(true)
                    setBoundingBoxVisibility(false)
                }}
                >
                Rotation
            </button>
            <button
                className="h-8 bg-blue-500 hover:bg-blue-700 text-white font-bold text-sm rounded"
                key={'Scale-reset'}
                onClick={() => {               
                    setScale(1) 
                    setResetScale(true)
                    setBoundingBoxVisibility(false)
                }}
                >
                Scale
            </button>
            <button
                className="h-8 bg-blue-500 hover:bg-blue-700 text-white font-bold text-sm rounded"
                key={'Camera-reset'}
                onClick={() => {               
                    setCameraPosition([4, 2, 5])
                }}
                >
                Camera
            </button>
        </div>
    </Suspense>
    )
}

export default ResetButtons