import THREE from "three";
import create from "zustand";

// class animationMixed {
//     index: number;
//     animation: string;
// }

export interface AnimationsState {
    playingAnimation: boolean;
    animationsTarget: string[];
    currentAnimation: {index: number, name: string};
    playAnimations: (value: boolean) => void;
    initAnimationButtons: (value: string) => void;
    clearAnimationsTarget: () => void;
    setCurrentAnimation: (index: number, animation: string) => void;
}

export const useAnimationsGlobal = create<AnimationsState>((set) => ({
    playingAnimation: false,
    animationsTarget: [],
    currentAnimation: {index: 10000, name: 'undefined'},
    playAnimations: (value: boolean) => {
        set((state) => ({            
            ...state,
            playingAnimation: value
        }))
    },
    initAnimationButtons: (value: string) => {
        set((state) => ({            
            animationsTarget:[
                ...state.animationsTarget,
                value
            ]
        }))
    },
    clearAnimationsTarget: () => {
        set(() => ({            
            animationsTarget:[]
        }))
    },
    setCurrentAnimation: (index: number, animation: string) => {
        set((state) => ({            
            ...state,
            currentAnimation: {index: index, name: animation}
        }))
    }
}));
