import create from "zustand";

export interface PanelState {
    showBoundingBox: boolean;
    hasRescaled: boolean;
    toggleBoundingBoxVisibility: () => void;
    setBoundingBoxVisibility: (value: boolean) => void;
    setHasRescaled: (value: boolean) => void;
}

export const useBoundingBox = create<PanelState>((set) => ({
    showBoundingBox: false,
    hasRescaled: false,
    toggleBoundingBoxVisibility: () =>
        set((state) => {
        return {
            ...state,
            showBoundingBox: !state.showBoundingBox
        };
        }),
    setBoundingBoxVisibility: (value) =>
        set((state) => {
        return {
            ...state,
            showBoundingBox: value
        };
        }),
    setHasRescaled: (value: boolean) =>
        set((state) => {
        return {
            ...state,
            hasRescaled: value
        };
        })
}));