import create from "zustand";

export interface EnvironmentState {
    env: string;
    //envId: number;
    setEnvironment: (value: string) => void;
    //setEnvironmentId: (value: number) => void;
}

export const useEnvironment = create<EnvironmentState>((set) => ({
    env: 'environment/lighting_1.hdr',
    setEnvironment: (value) =>
        set((state) => {
        return {
            ...state,
            env: value
        };
    })
    // ,
    // envId: 1,
    // setEnvironmentId: (value) =>
    //     set((state) => {
    //     return {
    //         ...state,
    //         envId: value
    //     };
    // })
}));
