import { useEffect, useMemo, useRef, useState } from "react";
import { TextureLoader, ClampToEdgeWrapping, CompressedTextureLoader, LoadingManager, Texture } from "three";
import { useImage } from "../hooks/useImage";


interface ImageProps {
    // position: [number, number, number];
    // rotation: [number, number, number];
    //scale: [number, number, number];
}

let scaleHeight = 4.4;
let scaleWidth = 4.4;
let imgWidth = 10;
let imgHeight = 10;
let dataURL = '';
let pixelWidth = 1;
let pixelHeight = 1;
let canvasWidth = 1;
let canvasHeight = 1;

const ImageTarget = (props: ImageProps) => {

    const { imageUrl, updateImage } = useImage((state) => state);
    const [loaded, setLoaded] = useState(false);
    const [dataUrl, setUrl] = useState('undefined');

    const img = new Image();
    const frameSize = 50;

    let texture = useMemo(() => new TextureLoader().load(dataUrl), [dataUrl]);
    
    useEffect(() => {
        if(updateImage === true){
            img.crossOrigin = "anonymous";
            img.src = imageUrl;
            img.onload = () => {
                imgWidth = img.width;
                imgHeight = img.height;
            };
            const canvas = document.createElement('canvas')

            if(imgWidth >= imgHeight){ 
                canvas.width = canvas.height = imgWidth
            }else{ canvas.width = canvas.height = imgHeight }

            const context = canvas.getContext('2d')
            pixelWidth = imgWidth;
            pixelHeight = imgHeight;
            canvasWidth = canvas.width;
            canvasHeight = canvas.height;

            if(context !== null){
                img.addEventListener("load", () => {
                    context.fillStyle = "transparent";
                    context.fillRect(0, 0, canvas.width, canvas.height)                
                    context.drawImage(img, frameSize, frameSize, pixelWidth - 2 * frameSize, pixelHeight - 2 * frameSize); 

                    dataURL = canvas.toDataURL();
                });
            }    
            setUrl(dataURL)           
            setTimeout(() => {
                setLoaded(true)
            }, 500)
        }        
    },[img])

    useEffect(() =>{
        let widthScaler = (1 - pixelWidth / canvasWidth) / 2;
        let heightScaler = (1 - pixelHeight / canvasHeight) / 2;
        texture.wrapS = texture.wrapT = ClampToEdgeWrapping;
        texture.offset.set(-widthScaler, heightScaler)
        texture.repeat.set(1, 1)
    },[texture])

    if(loaded){
            return ( 
                <mesh
                    receiveShadow
                    position={[0, 0.01, 0]}
                    rotation={[-Math.PI / 2, 0, 0]}>
                        <planeGeometry attach="geometry" args={[scaleWidth, scaleHeight]} />
                        <meshStandardMaterial transparent map={texture} />
                </mesh>
            );
    }else{
        return(null)
    }

};

export default ImageTarget;
