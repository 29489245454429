import classNames from "classnames";
import { Suspense } from "react";
import { useEnvironment } from "../hooks/useEnvironment";


const EnvironmentButtons = ({}) => {

    const { env, setEnvironment } = useEnvironment((state) => state)

    return(
    <Suspense fallback={null}>
        <h1>Lighting</h1>
        <div className="grid grid-cols-3 gap-1">             
            <button
                className={classNames({
                'bg-blue-700': env === 'environment/lighting_2.hdr',
                'bg-blue-500': env !== 'environment/lighting_2.hdr',
                },"h-8 hover:bg-blue-600 text-white font-bold text-sm rounded")}
                key={'Translation-reset'}
                onClick={() => {               
                    setEnvironment('environment/lighting_2.hdr')
                }}
                >
                Industrial
            </button>
            <button
                className={classNames({
                    'bg-blue-700': env === 'environment/lighting_1.hdr',
                    'bg-blue-500': env !== 'environment/lighting_1.hdr',
                    },"h-8 hover:bg-blue-600 text-white font-bold text-sm rounded")}
                key={'Rotation-reset'}
                onClick={() => {               
                    setEnvironment('environment/lighting_1.hdr')
                }}
                >
                Forest
            </button>
            <button
                className={classNames({
                    'bg-blue-700': env === 'environment/lighting_3.hdr',
                    'bg-blue-500': env !== 'environment/lighting_3.hdr',
                    },"h-8 hover:bg-blue-600 text-white font-bold text-sm rounded")}
                key={'Scale-reset'}
                onClick={() => {               
                    setEnvironment('environment/lighting_3.hdr')
                }}
                >
                Simple
            </button>
        </div>
    </Suspense>
    )
}

export default EnvironmentButtons