import { useFrame, useThree } from "@react-three/fiber";
import { useEffect } from "react";
import { useScreenshot } from "../hooks/useScreenshot";

const Screenshot = () => {
    const gl = useThree((state) => state.gl)
    const { loadedScreenshot, setScreenshot, loadScreenshot } = useScreenshot((state) => state)

    // const getScreenshot = () => {
    //     const link = document.createElement('a')
    //     link.setAttribute('download', 'canvas.png')
    //     link.setAttribute('href', gl.domElement.toDataURL('image/png').replace('image/png', 'image/octet-stream'))
    //     link.click()
    // }

    useEffect(() => {
        //console.log(loadedScreenshot)
        if(loadedScreenshot){
            //console.log(gl.domElement.toDataURL('image/png'))
            setScreenshot(gl.domElement.toDataURL('image/png'))
            //getScreenshot()
            loadScreenshot(false) 
        }
    },[loadedScreenshot])

    return (null);
};

export default Screenshot;
