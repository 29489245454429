import {Euler, Quaternion} from "three";
import create from "zustand";


export interface TargetState {
    position: [number, number, number];
    rotation: [number, number, number];
    scale: [number, number, number];
    mode: string;
    resetRotate: boolean;
    resetTranslate: boolean;
    resetScale: boolean;
    updateRotate: boolean;
    updateTranslate: boolean;
    updateScale: boolean;
    setResetRotate: (value: boolean) => void;
    setResetTranslate: (value: boolean) => void;
    setResetScale: (value: boolean) => void;
    setUpdateRotate: (value: boolean) => void;
    setUpdateTranslate: (value: boolean) => void;
    setUpdateScale: (value: boolean) => void;
    setMode: (value: string) => void;
    setPosition: (value: [number, number, number]) => void;
    setPositionX: (value: number) => void;
    setPositionY: (value: number) => void;
    setPositionZ: (value: number) => void;
    increasePositionX: (value: number) => void;
    decreasePositionX: (value: number) => void;
    increasePositionY: (value: number) => void;
    decreasePositionY: (value: number) => void;
    increasePositionZ: (value: number) => void;
    decreasePositionZ: (value: number) => void;
    setRotation: (value: [number, number, number]) => void;
    setRotationX: (value: number) => void;
    setRotationY: (value: number) => void;
    setRotationZ: (value: number) => void;
    increaseRotationX: (value: number) => void;
    decreaseRotationX: (value: number) => void;
    increaseRotationY: (value: number) => void;
    decreaseRotationY: (value: number) => void;
    increaseRotationZ: (value: number) => void;
    decreaseRotationZ: (value: number) => void;
    setScale: (value: number) => void;
    increaseScale: (value: number) => void;
    decreaseScale: (value: number) => void;
}


export const useTransforms = create<TargetState>((set) => ({
    position: [0, 0, 0],
    rotation: [0, 0, 0],
    scale: [1, 1, 1],
    mode: 'translate',
    setMode: (value) => 
        set((state) => {
        return {
            ...state,
            mode: value
        }        
        }),
    resetRotate: false,
    setResetRotate: (value) => 
        set((state) => {
        return {
            ...state,
            resetRotate: value
        }        
        }),
    resetTranslate: false,
    setResetTranslate: (value) => 
        set((state) => {
        return {
            ...state,
            resetTranslate: value
        }        
        }),
    resetScale: false,
    setResetScale: (value) => 
        set((state) => {
        return {
            ...state,
            resetScale: value
        }        
        }),
    updateScale: false,
    setUpdateScale: (value) => 
        set((state) => {
        return {
            ...state,
            updateScale: value
        }        
        }),
    updateTranslate: false,
    setUpdateTranslate: (value) => 
        set((state) => {
        return {
            ...state,
            updateTranslate: value
        }        
        }),
    updateRotate: false,
    setUpdateRotate: (value) => 
        set((state) => {
        return {
            ...state,
            updateRotate: value
        }        
        }),
    setPosition: (value) =>
        set((state) => {
        return {
            ...state,
            position: value
        }        
        }),
    setPositionX: (value) =>
        set((state) => {
        state.position[0] = value
        return {
            ...state,
            position: state.position
        }        
        }),
    setPositionY: (value) =>
        set((state) => {
        state.position[1] = value
        return {
            ...state,
            position: state.position
        }        
        }),
    setPositionZ: (value) =>
        set((state) => {
        state.position[2] = value
        return {
            ...state,
            position: state.position
        }        
        }),
    increasePositionX: (value) =>
        set((state) => {
        state.position[0] = state.position[0] + value
        return {
            ...state,
            position: state.position
        };
        }),
    decreasePositionX: (value) =>
        set((state) => {
        state.position[0] = state.position[0] - value
        return {
            ...state,
            position: state.position
        };
        }),
    increasePositionY: (value) =>
        set((state) => {
        state.position[1] = state.position[1] + value
        return {
            ...state,
            position: state.position
        };
        }),
    decreasePositionY: (value) =>
        set((state) => {
        state.position[1] = state.position[1] - value
        return {
            ...state,
            position: state.position
        };
        }),
    increasePositionZ: (value) =>
    set((state) => {
    state.position[2] = state.position[2] + value
    return {
        ...state,
        position: state.position
    };
    }),
    decreasePositionZ: (value) =>
        set((state) => {
        state.position[2] = state.position[2] - value
        return {
            ...state,
            position: state.position
        };
        }),
    setRotation: (value) =>
        set((state) => {
        return {
            ...state,
            rotation: value
        }        
        }),
    setRotationX: (value) =>
        set((state) => {
        state.rotation[0] = value
        return {
            ...state,
            rotation: state.rotation
        }        
        }),
    setRotationY: (value) =>
        set((state) => {
        state.rotation[1] = value
        return {
            ...state,
            rotation: state.rotation
        }        
        }),
    setRotationZ: (value) =>
        set((state) => {
        state.rotation[2] = value
        return {
            ...state,
            rotation: state.rotation
        }        
        }),
    increaseRotationX: (value) =>
        set((state) => {
        state.rotation[0] = state.rotation[0] + value
        return {
            ...state,
            rotation: state.rotation
            //rotation: parseRotation(state.rotation, value, 'x', 'increase')
        };
        }),
    decreaseRotationX: (value) =>
        set((state) => {
        state.rotation[0] = state.rotation[0] - value
        return {
            ...state,
            //rotation: parseRotation(state.rotation, value, 'x', 'decrease')
            rotation: state.rotation
        };
        }),
    increaseRotationY: (value) =>
        set((state) => {
        state.rotation[1] = state.rotation[1] + value
        return {
            ...state,
            rotation: state.rotation
        };
        }),
    decreaseRotationY: (value) =>
        set((state) => {
        state.rotation[1] = state.rotation[1] - value
        return {
            ...state,
            rotation: state.rotation
        };
        }),
    increaseRotationZ: (value) =>
    set((state) => {
        state.rotation[2] = state.rotation[2] + value
        return {
            ...state,
            rotation: state.rotation
        };
    }),
    decreaseRotationZ: (value) =>
    set((state) => {
        state.rotation[2] = state.rotation[2] - value
        return {
            ...state,
            rotation: state.rotation
        };
    }),
    setScale: (value) =>
        set((state) => {
        state.scale[0] = value
        state.scale[1] = value
        state.scale[2] = value
        return {
            ...state,
            scale: state.scale
        }        
        }),
    increaseScale: (value) =>
        set((state) => {
        state.scale[0] = state.scale[0] + value
        state.scale[1] = state.scale[1] + value
        state.scale[2] = state.scale[2] + value
        return {
            ...state,
            scale: state.scale
        };
        }),
    decreaseScale: (value) =>
        set((state) => {
        state.scale[0] = state.scale[0] - value
        state.scale[1] = state.scale[1] - value
        state.scale[2] = state.scale[2] - value
        return {
            ...state,
            scale: state.scale
        };
        })
}));
