import Server from "../server/Server";
import PanelButton from "./PanelButton";

const Overlay = () => {
  
  return (
    <div>
      <PanelButton/> 
      <Server/>
    </div> 
  );
};

export default Overlay;
