import { Suspense, useState } from "react";
import { useImage } from "../hooks/useImage";
import { usePanel } from "../hooks/usePanel";


const PanelButton = ({}) => {

    const {showPanel, togglePanel} = usePanel((state) => state);    
    const { setUpdateImage } = useImage((state) => state);

    return(
        <Suspense fallback={null}>
            <div className="flex flex-row justify-end w-full p-5 absolute z-10">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded absolute z-10"
                    onClick={() => {                 
                        togglePanel(showPanel)
                        //Find correct spot so that image is always showing
                        setUpdateImage(false)
                    }}
                    >
                    {'CONTROLS'}
                </button>
            </div>
        </Suspense>
    )
}

export default PanelButton