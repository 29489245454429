import { Suspense } from "react";
import { directions } from "../canvas/ButtonControler";
import { useTransforms } from "../hooks/useTransforms";


const TranslationButtons = ({}) => {
    
    const { mode, setMode } = useTransforms((state) => state)
    
    return(
        <Suspense fallback={null}>
            <h1>Move</h1>
            <div className="grid grid-cols-4 gap-1 mb-2">
                <div></div>
                <button
                //bg-indigo-400
                    className="col-span-2 h-8 bg-emerald-400 hover:bg-emerald-500 text-white font-bold text-sm rounded"
                    onMouseDown={() => {                 
                        directions.add('positionNegZ')
                        setMode('translate')
                    }}
                    onMouseUp={() => {           
                        directions.delete('positionNegZ')
                    }}
                    onMouseEnter={() => {}}
                    onMouseOut={() => {
                        directions.delete('positionNegZ')
                    }}
                    >
                    <div className="w-full h-full flex flex-col justify-center items-center">
                        <img className="w-5 h-5"  src={'icons/up_64.png'}></img>
                    </div>
                    {/* {'- Z'} */}
                </button>
                <div></div>
                <button
                    className="h-8 bg-rose-400 hover:bg-rose-500 text-white font-bold text-sm rounded"
                    onMouseDown={() => {                 
                        directions.add('positionNegX')
                        setMode('translate')
                    }}
                    onMouseUp={() => {           
                        directions.delete('positionNegX')
                    }}
                    onMouseEnter={() => {}}
                    onMouseOut={() => {
                        directions.delete('positionNegX')
                    }}
                    >
                    <div className="w-full h-full flex flex-col justify-center items-center">
                        <img className="w-5 h-5"  src={'icons/left_64.png'}></img>
                    </div>
                    {/* {'- X'} */}
                </button>
                <button
                //bg-emerald-400
                    className="bg-indigo-400 hover:bg-indigo-500 text-white font-bold text-sm rounded"
                    onMouseDown={() => {                 
                        directions.add('positionNegY')
                        setMode('translate')
                    }}
                    onMouseUp={() => {           
                        directions.delete('positionNegY')
                    }}
                    onMouseEnter={() => {}}
                    onMouseOut={() => {
                        directions.delete('positionNegY')
                    }}
                    >
                    <div className="w-full h-full flex flex-col justify-center items-center">
                        <img className="w-5 h-5"  src={'icons/forward_64.png'}></img>
                    </div>
                    {/* {'- Y'} */}
                </button>
                <button
                //bg-emerald-400
                    className="bg-indigo-400 hover:bg-indigo-500 text-white font-bold text-sm rounded p-1"
                    onMouseDown={() => {                 
                        directions.add('positionPositiveY')
                        setMode('translate')
                    }}
                    onMouseUp={() => {           
                        directions.delete('positionPositiveY')
                    }}
                    onMouseEnter={() => {}}
                    onMouseOut={() => {
                        directions.delete('positionPositiveY')
                    }}
                    >
                    <div className="w-full h-full flex flex-col justify-center items-center">
                        <img className="w-5 h-5"  src={'icons/backwards_64.png'}></img>
                    </div>
                    {/* {'Y'} */}
                </button>
                <button
                    className="bg-rose-400 hover:bg-rose-500 text-white font-bold text-sm rounded p-1"
                    onMouseDown={() => {                 
                        directions.add('positionPositiveX')
                        setMode('translate')
                    }}
                    onMouseUp={() => {           
                        directions.delete('positionPositiveX')
                    }}
                    onMouseEnter={() => {}}
                    onMouseOut={() => {
                        directions.delete('positionPositiveX')
                    }}
                    >
                    <div className="w-full h-full flex flex-col justify-center items-center">
                        <img className="w-5 h-5"  src={'icons/right_64.png'}></img>
                    </div>
                    {/* {'X'} */}
                </button>
                <div></div>
                <button
                //bg-indigo-400
                    className="col-span-2 h-8 bg-emerald-400 hover:bg-emerald-500 text-white font-bold text-sm rounded"             
                    onMouseDown={() => {                 
                        directions.add('positionPositiveZ')
                        setMode('translate')
                    }}
                    onMouseUp={() => {           
                        directions.delete('positionPositiveZ')
                    }}
                    onMouseEnter={() => {}}
                    onMouseOut={() => {
                        directions.delete('positionPositiveZ')
                    }}
                    >
                    <div className="w-full h-full flex flex-col justify-center items-center">
                        <img className="w-5 h-5"  src={'icons/down_64.png'}></img>
                    </div>
                    {/* {'Z'} */}
                </button>
                <div></div>
            </div>
        </Suspense>
    )
}

export default TranslationButtons