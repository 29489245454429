import { Suspense } from "react";
import { useAnimationsGlobal } from "../hooks/useAnimationsGlobal";


const AnimationButtons = ({}) => {

    const { animationsTarget, setCurrentAnimation, playingAnimation, playAnimations } = useAnimationsGlobal((state) => state);
    
    if(Object.keys(animationsTarget).length !== 0)
    {
        if(playingAnimation === true){
            return(
                <Suspense fallback={null}>
                    <h1>Animations</h1>
                    <div className="flex flex-col">                
                        {animationsTarget.map((item, index) => 
                        <button
                            className="h-8 m-1 bg-blue-500 hover:bg-blue-700 text-white font-bold text-sm rounded"
                            key={item}
                            onClick={() => {               
                                setCurrentAnimation(index, item)
                            }}
                            >
                            {item}
                        </button>    
                        )}      
                    </div>
                </Suspense>
            )
        }else{
            return(
                <Suspense fallback={null}>
                    <h1>Animations</h1>
                    <div className="flex flex-col">                
                        <button
                            className="h-8 m-1 bg-blue-500 hover:bg-blue-700 text-white font-bold text-sm rounded"
                            key={'null-button'}
                            onClick={() => {               
                                playAnimations(true)
                                setCurrentAnimation(0, animationsTarget[0])
                            }}
                            >
                            Play
                        </button>          
                    </div>
                </Suspense>
            )
        }        
    }else{
        return(null)
    }
}

export default AnimationButtons