import { ChangeEvent, SetStateAction, Suspense, useEffect, useState } from "react";
import { useTransforms } from '../hooks/useTransforms';
import {directions} from '../canvas/ButtonControler'
import { useModel } from "../hooks/useModel";

let valPosX, valPosY, valPosZ, valRotX, valRotY, valRotZ = 0;
let valScale = 1; 
let initalPosX, initalPosY, initalPosZ, initalRotX, initalRotY, initalRotZ, initalScale = '';

const TransformInputs = ({}) => {

    const { position, rotation, scale, resetRotate, 
        updateRotate, resetTranslate, resetScale,
        setPositionX, setPositionY,  setPositionZ, 
        setRotationX, setRotationY, setRotationZ, setUpdateRotate, 
        setResetScale, setResetTranslate, setScale} = useTransforms((state) => state);
    const { hasLoaded } = useModel((state) => state)

    initalPosX = position[0].toString().substring(0, 5);
    initalPosY = position[1].toString().substring(0, 5);
    initalPosZ = position[2].toString().substring(0, 5);
    initalRotX = rotation[0].toString().substring(0, 5);
    initalRotY = rotation[1].toString().substring(0, 5);
    initalRotZ = rotation[2].toString().substring(0, 5);
    initalScale = scale[0].toString().substring(0, 5);

    const [valuePosX, setInputFieldValuePosX] = useState<string>(initalPosX);
    const [valuePosY, setInputFieldValuePosY] = useState<string>(initalPosY);
    const [valuePosZ, setInputFieldValuePosZ] = useState<string>(initalPosZ);
    const [valueRotX, setInputFieldValueRotX] = useState<string>(initalRotX);
    const [valueRotY, setInputFieldValueRotY] = useState<string>(initalRotY);
    const [valueRotZ, setInputFieldValueRotZ] = useState<string>(initalRotZ);
    const [valueScale, setInputFieldValueScale] = useState<string>(initalScale);
    //const [buttonIsActive, setButtonIsActive] = useState<boolean>(false);
    //const [hasLoaded, setHasLoaded] = useState<boolean>(false);

    const convertRadiansToDegrees = (radians: number) => {
        let degrees = radians * 180 / Math.PI;
        //console.log(radians, degrees)
        return degrees.toString()
    }

    const convertDegreesToRadians = (degrees: string) => {
        let val = parseFloat(degrees); 
        let radians = val * Math.PI / 180;
        return radians
    }

    const checkIsNan = (value: string) => {
        let val = parseFloat(value); 
        if(value === ""){ return '0' }
        if(isNaN(val)){ return '0' }
        if(!isNaN(val)){
            if(value === '0-' || value === '-0'){ return `-` }
            if(value.slice(-1) === '.'){ return `${val.toString().substring(0, 5)}.` }
            return val.toString().substring(0, 5)
        }
        return '0'
    }

    //updates values in view when buttons are pressed
    useEffect(() => {
        if(resetTranslate){
            setInputFieldValuePosX(checkIsNan(position[0].toString().substring(0, 5)))
            setInputFieldValuePosY(checkIsNan(position[1].toString().substring(0, 5)))
            setInputFieldValuePosZ(checkIsNan(position[2].toString().substring(0, 5)))
            setResetTranslate(false)
        }
        if(resetScale){
            setInputFieldValueScale(checkIsNan(scale[0].toString().substring(0, 5)))
            setResetScale(false)
        }
        if(resetRotate){
            setInputFieldValueRotX(checkIsNan(convertRadiansToDegrees(rotation[0])))
            setInputFieldValueRotY(checkIsNan(convertRadiansToDegrees(rotation[1])))
            setInputFieldValueRotZ(checkIsNan(convertRadiansToDegrees(rotation[2])))
        }
        if(directions.size !== 0 ){
            setInputFieldValuePosX(checkIsNan(position[0].toString().substring(0, 5)))
            setInputFieldValuePosY(checkIsNan(position[1].toString().substring(0, 5)))
            setInputFieldValuePosZ(checkIsNan(position[2].toString().substring(0, 5)))
            setInputFieldValueRotX(checkIsNan(convertRadiansToDegrees(rotation[0])))
            setInputFieldValueRotY(checkIsNan(convertRadiansToDegrees(rotation[1])))
            setInputFieldValueRotZ(checkIsNan(convertRadiansToDegrees(rotation[2])))
            setInputFieldValueScale(checkIsNan(scale[0].toString().substring(0, 5)))
        }
    })

    useEffect(() => {
        //if(hasLoaded === false){
            //console.log('rotate: '+valueRotX, valueRotY, valueRotZ)
            setInputFieldValueRotX(checkIsNan(convertRadiansToDegrees(parseFloat(valueRotX))))
            setInputFieldValueRotY(checkIsNan(convertRadiansToDegrees(parseFloat(valueRotY))))
            setInputFieldValueRotZ(checkIsNan(convertRadiansToDegrees(parseFloat(valueRotZ))))
            //setHasLoaded(true)
        //}
    },[hasLoaded])

    const handleChangePositionX = (e: ChangeEvent<HTMLInputElement>) => {
        //setButtonIsActive(true)
        setInputFieldValuePosX(checkIsNan(e.target.value));
        setPositionX(parseFloat(checkIsNan(e.target.value)));
        //setButtonIsActive(false)
    }

    const handleChangePositionY = (e: ChangeEvent<HTMLInputElement>) => {
        setInputFieldValuePosY(checkIsNan(e.target.value));
        setPositionY(parseFloat(checkIsNan(e.target.value)))
    }

    const handleChangePositionZ = (e: ChangeEvent<HTMLInputElement>) => {
        setInputFieldValuePosZ(checkIsNan(e.target.value));
        setPositionZ(parseFloat(checkIsNan(e.target.value)))
    }

    const handleChangeRotationX = (e: ChangeEvent<HTMLInputElement>) => {
        console.log(checkIsNan(e.target.value))
        setInputFieldValueRotX(checkIsNan(e.target.value));
        setRotationX(convertDegreesToRadians(checkIsNan(e.target.value)))
        setUpdateRotate(true)
    }

    const handleChangeRotationY = (e: ChangeEvent<HTMLInputElement>) => {
        setInputFieldValueRotY(checkIsNan(e.target.value));
        setRotationY(convertDegreesToRadians(checkIsNan(e.target.value)))
        setUpdateRotate(true)
    }

    const handleChangeRotationZ = (e: ChangeEvent<HTMLInputElement>) => {
        setInputFieldValueRotZ(checkIsNan(e.target.value));
        setRotationZ(convertDegreesToRadians(checkIsNan(e.target.value)))
        setUpdateRotate(true)
    }
 
    const handleChangeScale = (e: ChangeEvent<HTMLInputElement>) => {
        setInputFieldValueScale(checkIsNan(e.target.value));
        valScale = parseFloat(checkIsNan(e.target.value));
        setScale(valScale)
    }  
    
    return(
    <Suspense fallback={null}>
        {/* <h1>Transforms</h1> */}
        <div className="grid grid-cols-4 gap-1 mb-2">
            <div className="text-sm">Move</div>
            <input
                className={'text-right'}
                value={valuePosX}
                onChange={handleChangePositionX}
            />
            <input
                className={'text-right'}
                value={valuePosY}
                onChange={handleChangePositionY}
            />
            <input
                className={'text-right'}
                value={valuePosZ}
                onChange={handleChangePositionZ}
            />
            <div className="text-sm">Rotate</div>
            <input
                className={'text-right'}
                value={valueRotX}
                onChange={handleChangeRotationX}
            />
            <input
                className={'text-right'}
                value={valueRotY}
                onChange={handleChangeRotationY}
            />
            <input
                className={'text-right'}
                value={valueRotZ}
                onChange={handleChangeRotationZ}
            />                
            <div className="text-sm">Scale</div>
            <input
                className={'text-right'}
                value={valueScale}
                onChange={handleChangeScale}
            />
        </div>
    </Suspense>
    )
}

export default TransformInputs

function setResetRotate(arg0: boolean) {
    throw new Error("Function not implemented.");
}
function setUpdateRotate(arg0: boolean) {
    throw new Error("Function not implemented.");
}

