import { FC, useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import Lights from "./Lights";
import Model from "../primitives/Model";
import ImageTarget from "../primitives/ImageTarget";
import ButtonController from "./ButtonControler";
import Plane from './Plane'
import { useTransforms } from "../hooks/useTransforms";
import Controls from './Controls'
import CameraController from './CameraController'
import JsonController from "../pages/JsonController";
import { useModel } from "../hooks/useModel";
import Screenshot from "./Screenshot";


const CanvasLayout: FC = () => {

    const { hasLoaded } = useModel((state) => state);
    const { position, rotation, scale } = useTransforms((state) => state);

    const [targetPosition, setTargetPosition] = useState<[number, number, number]>([0, 0, 0]);
    const [targetRotation, setTargetRotation] = useState<[number, number, number]>([0, 0, 0]);
    const [targetScale, setTargetScale] = useState<[number, number, number]>([1, 1, 1]);
  
    useEffect(() => {
        setTargetPosition(position)
        setTargetRotation(rotation)
        setTargetScale(scale)
    })


    return (
        <Canvas
        shadows
        gl={{
            powerPreference: "high-performance",
            antialias: true,
            stencil: true,
            depth: true,
            preserveDrawingBuffer: true
        }} 
        camera={{
            //position: [0, 2, 4],
            aspect: 0.1,
            fov: 50, 
            near: 0.1,
            far: 100,
        }}
        //onPointerMissed={() => setTarget(null!)}
        >
            {/* <Preload all /> */}
            <Screenshot/>
            {/* <Perf position={"bottom-left"} />  */}
            <ButtonController/>
            <CameraController/>
            <JsonController/>   
            <Lights/>
            <Plane/>
            {hasLoaded &&
            <Model 
                position={[targetPosition[0], targetPosition[1], targetPosition[2]]}
                // rotation-x={targetRotation[0]}
                // rotation-y={targetRotation[1]}
                // rotation-z={targetRotation[2]}
                // rotation={[targetRotation[0], targetRotation[1], targetRotation[2]]}
                scale={[targetScale[0], targetScale[1], targetScale[2]]}
                />
            }
            <ImageTarget 
                // position={[0, 0.01, 0]}
                // rotation={[-Math.PI / 2, 0, 0]}
                //scale={[6, 4, 0.1]}
                />
            <Controls/>
            <color attach="background" args={['#f2f2f2']} />
            <fog attach="fog" args={['#f2f2f2', 10, 45]} />
        </Canvas>
    );
};

export default CanvasLayout;