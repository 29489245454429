import create from "zustand";

export interface TargetState {
    model: string;
    modelRef: any;
    modelPosition: [number, number, number],
    modelRotation: [number, number, number],
    modelScale: [number, number, number],
    hasLoaded: boolean;
    isInitialized: boolean;
    setModel: (value: string) => void;
    setModelRef: (value: React.MutableRefObject<any>) => void;
    setModelHasLoaded: (value: boolean) => void;
    setIsInitialized: (value: boolean) => void;
    setModelPosition: (x: number, y: number, z: number) => void;
    setModelRotation: (x: number, y: number, z: number) => void;
    setModelScale: (x: number, y: number, z: number) => void;
}

export const useModel = create<TargetState>((set) => ({
    model: '',
    setModel: (value) =>
        set((state) => {
        return {
            ...state,
            model: value
        };
    }),
    modelRef: undefined,
    setModelRef: (value) =>
    set((state) => {
        return {
            ...state,
            modelRef: value
        };
    }),
    hasLoaded: false,
    setModelHasLoaded: (value) =>
        set((state) => {
        return{
            ...state,
            hasLoaded: value
        }
    }),
    isInitialized: false,
    setIsInitialized: (value) =>
        set((state) => {
        return{
            ...state,
            isInitialized: value
        }
    }),      
    modelPosition: [0, 0, 0],
    setModelPosition: (x, y, z) =>
        set((state) => {
        return{
            ...state,
            modelPosition: [x, y, z]
        }
    }),   
    modelRotation: [0, 0, 0],
    setModelRotation: (x, y, z) =>
        set((state) => {
        return{
            ...state,
            modelRotation: [x, y, z]
        }
    }),   
    modelScale: [1, 1, 1],
    setModelScale: (x, y, z) =>
        set((state) => {
        return{
            ...state,
            modelScale: [x, y, z]
        }
    }),  
}));