import create from "zustand";

export interface TargetState {
    targets: any[];
    setTargets: (value: any[]) => void;
}

export const useTargets = create<TargetState>((set) => ({
    targets: [],
    setTargets: (value) =>
        set((state) => {
        return {
            ...state,
            targets: value
        };
    })
}));