import create from "zustand";

export interface TargetState {
    screenshot: string;
    loadedScreenshot: boolean;
    setScreenshot: (value: string) => void;
    loadScreenshot: (value: boolean) => void;
}

export const useScreenshot = create<TargetState>((set) => ({
    screenshot: '',
    setScreenshot: (value) =>
        set((state) => {
            // if(value !== ""){
                return {
                    ...state,
                    screenshot: value
                };
            // }else{
            //     return {
            //         // ...state,
            //         // screenshot: value
            //     };
            // }
    }),
    loadedScreenshot: false,
    loadScreenshot: (value) =>
        set((state) => {
        return {
            ...state,
            loadedScreenshot: value
        };
    }),
}));